.carousel {
    --swiper-navigation-size: 20px;
    margin-bottom: 20px;
}

.carouselSlideWrapper .carouselItem {
    display: flex;
    position: relative;
    min-height: 500px;
    background-color: #02161f;
}

.carouselSlideWrapper .carouselItem .itemInfo {
    width: 100%;
    background: #02161f;
    background: linear-gradient(
        90deg,
        rgba(2, 22, 31, 1) 6%,
        rgba(1, 9, 12, 0.5032606792717087) 70%,
        rgba(0, 0, 0, 0) 100%
    );
    padding: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    z-index: 10;
}

.carouselSlideWrapper .carouselItem .itemInfo .logo {
    padding: 10px 0px;
    max-width: 300px;
    max-height: 100px;
    width: 300px;
    height: 100px;
    position: relative;
}

.carouselSlideWrapper .carouselItem .itemInfo .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 40px;
}

.carouselSlideWrapper .carouselItem .itemInfo .info .buttons {
    margin-top: 30px;
}

.carouselSlideWrapper .carouselItem .itemInfo .info .title {
    padding: 10px;
    color: #00ffb3;
}

.carouselSlideWrapper .carouselItem .itemInfo .info .mainInfo {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 991px) {
    .carouselSlideWrapper .carouselItem .itemInfo {
        width: 100%;
        background: rgb(2, 22, 31);
        background: linear-gradient(
            0deg,
            rgba(2, 22, 31, 1) 40%,
            rgba(2, 16, 22, 0.7721682422969187) 68%,
            rgba(1, 9, 12, 0.5032606792717087) 80%,
            rgba(0, 0, 0, 0) 100%
        );
        padding: 20px;
        align-items: center;
    }

    .carouselSlideWrapper .carouselItem .itemInfo .info {
        position: absolute;
        bottom: 40px;
    }

    .carouselSlideWrapper .carouselItem .itemBg {
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .carouselSlideWrapper .carouselItem .itemInfo .logo {
        width: 140px;
        padding-bottom: 100px;
    }

    .carouselSlideWrapper .carouselItem .itemInfo .info .title {
        visibility: visible;
    }

    /* .carousel > div:nth-child(1),
  .carousel > div:nth-child(2) {
    display: none;
  } */
}
